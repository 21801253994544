import displayTypes from '../constants/displayTypes'

const questions = [
  {
    components: [
      {
        order: 1,
        displayType: displayTypes.HEADER,
        text: 'Hej!\nHur beroende av cigg är du?'
      },
      {
        order: 3,
        displayType: displayTypes.PARAGRAPH,
        text:
          'Gör testet för att få reda på hur nikotinberoende du är. Baserat på testet blir du rekommenderad hur du kan gå tillväga för att bli rökfri.\nFagerströms test för nikotinberoende är använt av läkare världen över och har legat till grund för det här testet.'
      },
      {
        order: 5,
        displayType: displayTypes.ARROW_BUTTON,
        text: 'Starta testet',
        analytics: {
          category: 'Navigation',
          action: 'Started test'
        }
      }
    ]
  },
  {
    components: [
      {
        order: 1,
        displayType: displayTypes.HEADER,
        text:
          'Hur lång tid efter du har vaknat röker du dagens första cigarett?'
      },
      {
        order: 2,
        displayType: displayTypes.SELECT,
        options: [
          {
            text: 'Inom 5 min',
            value: 3,
            analytics: {
              category: 'Question',
              action: 'Q1: Time to first cigarette',
              label: 'Within 5 min'
            }
          },
          {
            text: '6-30 min',
            value: 2,
            analytics: {
              category: 'Question',
              action: 'Q1: Time to first cigarette',
              label: '6-30 min'
            }
          },
          {
            text: '31-60 min',
            value: 1,
            analytics: {
              category: 'Question',
              action: 'Q1: Time to first cigarette',
              label: '31-60 min'
            }
          },
          {
            text: 'Efter 1 timme',
            value: 0,
            analytics: {
              category: 'Question',
              action: 'Q1: Time to first cigarette',
              label: 'After 1 hour'
            }
          }
        ]
      }
    ]
  },
  {
    components: [
      {
        order: 1,
        displayType: displayTypes.HEADER,
        text:
          'Har du svårt att låta bli att röka i situationer där rökning ej är tillåten, t.ex. bio, teater?'
      },
      {
        order: 2,
        displayType: displayTypes.SELECT,
        options: [
          {
            text: 'Ja',
            value: 1,
            analytics: {
              category: 'Question',
              action: 'Q2: Difficulty refraining from smoking',
              label: 'Yes'
            }
          },
          {
            text: 'Nej',
            value: 0,
            analytics: {
              category: 'Question',
              action: 'Q2: Difficulty refraining from smoking',
              label: 'No'
            }
          }
        ]
      }
    ]
  },
  {
    components: [
      {
        order: 1,
        displayType: displayTypes.HEADER,
        text: 'Vilken cigarett är svårast att låta bli?'
      },
      {
        order: 2,
        displayType: displayTypes.SELECT,
        options: [
          {
            text: 'Dagens första cigg',
            value: 1,
            analytics: {
              category: 'Question',
              action: 'Q3: Most difficult cigarette to skip',
              label: 'First of the day'
            }
          },
          {
            text: 'Någon annan',
            value: 0,
            analytics: {
              category: 'Question',
              action: 'Q3: Most difficult cigarette to skip',
              label: 'Any other'
            }
          }
        ]
      }
    ]
  },
  {
    components: [
      {
        order: 1,
        displayType: displayTypes.HEADER,
        text: 'Hur många cigaretter röker du per dag?'
      },
      {
        order: 2,
        displayType: displayTypes.SELECT,
        options: [
          {
            text: '1-10',
            value: 0,
            analytics: {
              category: 'Question',
              action: 'Q4: Number cigarettes per day',
              label: '1-10'
            }
          },
          {
            text: '11-20',
            value: 1,
            analytics: {
              category: 'Question',
              action: 'Q4: Number cigarettes per day',
              label: '11-20'
            }
          },
          {
            text: '21-30',
            value: 2,
            analytics: {
              category: 'Question',
              action: 'Q4: Number cigarettes per day',
              label: '21-30'
            }
          },
          {
            text: 'Mer än 30',
            value: 3,
            analytics: {
              category: 'Question',
              action: 'Q4: Number cigarettes per day',
              label: 'More than 30'
            }
          }
        ]
      }
    ]
  },
  {
    components: [
      {
        order: 1,
        displayType: displayTypes.HEADER,
        text: 'Röker du mer under morgonens första timmar än senare på dagen?'
      },
      {
        order: 2,
        displayType: displayTypes.SELECT,
        options: [
          {
            text: 'Ja',
            value: 1,
            analytics: {
              category: 'Question',
              action: 'Q5: Smoke more in the morning',
              label: 'Yes'
            }
          },
          {
            text: 'Nej',
            value: 0,
            analytics: {
              category: 'Question',
              action: 'Q5: Smoke more in the morning',
              label: 'No'
            }
          }
        ]
      }
    ]
  },
  {
    components: [
      {
        order: 1,
        displayType: displayTypes.HEADER,
        text:
          'Röker du även om du är så sjuk att du stannar i sängen större delen av dagen?'
      },
      {
        order: 2,
        displayType: displayTypes.SELECT,
        options: [
          {
            text: 'Ja',
            value: 1,
            analytics: {
              category: 'Question',
              action: 'Q6: Smoke when sick',
              label: 'Yes'
            }
          },
          {
            text: 'Nej',
            value: 0,
            analytics: {
              category: 'Question',
              action: 'Q6: Smoke when sick',
              label: 'No'
            }
          }
        ]
      }
    ]
  },
  {
    components: [
      {
        order: 1,
        displayType: displayTypes.HEADER,
        text: 'Vad har du testat tidigare för att bli rökfri?'
      },
      {
        order: 2,
        displayType: displayTypes.HINT,
        text: 'Flera svarsalternativ möjliga.'
      },
      {
        order: 3,
        displayType: displayTypes.SELECT,
        options: [
          {
            text: 'Nikotinersättning (t.ex. tuggummi, spray, sugtabletter)',
            isMultipleSelect: true,
            analytics: {
              category: 'Question',
              action: 'Q7: Previous methods',
              label: 'Nicotine replacement'
            }
          },
          {
            text: 'Receptbelagt läkemedel',
            isMultipleSelect: true,
            analytics: {
              category: 'Question',
              action: 'Q7: Previous methods',
              label: 'Prescription drug'
            }
          },
          {
            text: 'Ersätta med snus eller e-cigg',
            isMultipleSelect: true,
            analytics: {
              category: 'Question',
              action: 'Q7: Previous methods',
              label: 'Replace with snus or e-cigg'
            }
          },
          {
            text: 'Har inte testat något',
            isMultipleSelect: true,
            analytics: {
              category: 'Question',
              action: 'Q7: Previous methods',
              label: 'None'
            }
          }
        ]
      }
    ]
  },
  {
    components: [
      {
        order: 1,
        displayType: displayTypes.HEADER,
        text: 'Vad stämmer in på dig?'
      },
      {
        order: 2,
        displayType: displayTypes.SELECT,
        options: [
          {
            text: 'Man',
            analytics: {
              category: 'Question',
              action: 'Q8: Gender',
              label: 'Male'
            }
          },
          {
            text: 'Kvinna',
            analytics: {
              category: 'Question',
              action: 'Q8: Gender',
              label: 'Female'
            }
          },
          {
            text: 'Annan könsidentifiering',
            analytics: {
              category: 'Question',
              action: 'Q8: Gender',
              label: 'Other'
            }
          }
        ]
      }
    ]
  },
  {
    components: [
      {
        order: 1,
        displayType: displayTypes.HEADER,
        text: 'Hur gammal är du?'
      },
      {
        order: 2,
        displayType: displayTypes.SELECT,
        options: [
          {
            text: '18-24 år',
            analytics: {
              category: 'Question',
              action: 'Q9: Age',
              label: '18-24'
            }
          },
          {
            text: '25-34 år',
            analytics: {
              category: 'Question',
              action: 'Q9: Age',
              label: '25-34'
            }
          },
          {
            text: '35-44 år',
            analytics: {
              category: 'Question',
              action: 'Q9: Age',
              label: '35-44'
            }
          },
          {
            text: '45-65 år',
            analytics: {
              category: 'Question',
              action: 'Q9: Age',
              label: '45-65'
            }
          },
          {
            text: '65+ år',
            analytics: {
              category: 'Question',
              action: 'Q9: Age',
              label: '65+'
            }
          }
        ]
      }
    ]
  },
  {
    components: [
      {
        order: 1,
        displayType: displayTypes.HEADER,
        text: 'Räknar ut ditt resultat...'
      }
    ]
  },
  {
    components: [
      {
        order: 1,
        displayType: displayTypes.SUBHEADER,
        text: 'Lågt beroende'
      },
      {
        order: 2,
        displayType: displayTypes.HEADER,
        text: 'Agera nu, ditt nikotinberoende är fortfarande lågt.'
      },
      {
        order: 3,
        displayType: displayTypes.PARAGRAPH,
        text:
          'Ju längre tiden går desto mer beroende riskerar du att bli. Det kommer vara enklare att sluta nu än att vänta till senare.'
      }
    ]
  },
  {
    components: [
      {
        order: 1,
        displayType: displayTypes.SUBHEADER,
        text: 'Måttligt beroende'
      },
      {
        order: 2,
        displayType: displayTypes.HEADER,
        text: 'Agera nu innan ditt beroende blir allvarligt.'
      },
      {
        order: 3,
        displayType: displayTypes.PARAGRAPH,
        text:
          'Om du inte väljer att sluta röka snart riskerar du att bli allvarligt beroende. Agera nu, medan det fortfarande inte är alltför svårt att sluta och innan din kropp tagit för mycket skada.'
      }
    ]
  },
  {
    components: [
      {
        order: 1,
        displayType: displayTypes.SUBHEADER,
        text: 'Allvarligt beroende'
      },
      {
        order: 2,
        displayType: displayTypes.HEADER,
        text:
          'Du verkar ha ett allvarligt beroende och kan ha behov av extra stöd.'
      },
      {
        order: 3,
        displayType: displayTypes.PARAGRAPH,
        text:
          'Det kan vara utmanande att sluta röka i din situation, men med rätt hjälp så kan du bli rökfri för gott.'
      }
    ]
  }
]

export default questions
