import React from 'react'
import arrowLeft from '../assets/images/arrowLeft.png'

const BackButton = ({ visible, onClick, enlarge, rotate }) => {
  if (!visible) return null

  return (
    <button
      className={rotate && 'alex-rotate'}
      style={{ border: 'none', backgroundColor: 'white' }}
      onClick={onClick}
    >
      <img style={{ height: enlarge ? 40 : 30, width: enlarge ? 40 : 30 }} src={arrowLeft} alt='Go back' />
    </button>
  )
}

export default BackButton
