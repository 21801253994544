import React from 'react'

const Header = ({ text }) => {
  const strings = text.split('\n')

  return strings.map((s, i) => {
    return (
      <h1 className='item-margin' key={i}>
        {s}
      </h1>
    )
  })
}

export default Header
