import React from 'react'
import { InlineShareButtons } from 'sharethis-reactjs'
import { AnalyticsService } from '../services'
import Button from './Button'
import insightsImg from '../assets/images/insights-screen.png'
import MedicheckButton from './MedicheckButton'

const alexAppLink = 'https://hialex.app.link/wuxmKkFIseb'
const medicheckLink = 'https://bit.ly/2NQEu3o'
const alexWebsiteLink =
  'http://alex-fagerstrom-website.s3-website-eu-west-1.amazonaws.com/'

const InsightsLowLevel = () => (
  <div className='alex-card'>
    <img src={insightsImg} alt='Insights screen' className='insights-image' />
    <div className='alex-card-body'>
      <h4 style={{ marginBottom: '20px' }}>Alex – din sluta röka-coach</h4>
      <p style={{ marginBottom: 20 }} className='small-text'>
        Du är fortfarande inte allvarligt beroende och kan antagligen klara av
        att sluta på egen hand med stöd från Alex.
      </p>
      <Button
        shrink
        color='white'
        text='Ladda ner gratis'
        className='item-margin'
        style={{ paddingLeft: 10, paddingRight: 10 }}
        onClick={() => {
          window.open(alexAppLink)
          AnalyticsService.track({
            category: 'Navigation',
            action: 'Download app'
          })
        }}
      />
    </div>
  </div>
)

const InsightsHighLevel = () => (
  <div className='alex-card'>
    <img src={insightsImg} alt='Insights screen' className='insights-image' />
    <div className='alex-card-body'>
      <h4 style={{ marginBottom: '20px' }}>Alex – din sluta röka-coach</h4>
      <p style={{ marginBottom: 20 }} className='small-text'>
        Ta hjälp av Alex för att sluta röka. Kanske i kombination med andra
        hjälpmedel?
      </p>
      <Button
        shrink
        color='white'
        text='Ladda ner gratis'
        className='item-margin'
        onClick={() => {
          window.open(alexAppLink)
          AnalyticsService.track({
            category: 'Navigation',
            action: 'Download app'
          })
        }}
      />
    </div>
  </div>
)

const Specialist = () => (
  <div className='alex-card'>
    <div className='alex-card-body'>
      <h4 style={{ marginBottom: '20px' }}>Prata med en specialist</h4>
      <p className='small-text'>
        Du som har testat nikotinersättning tidigare men fortfarande inte klarat av att bli rökfri kan få extra stöd från vården.
      </p>
      <br />
      <p className='small-text'>
        Ta kontakt med din vårdgivare för att få hjälp.
      </p>
      <br />
      <p className='small-text'>
        Du kan också välja att kontakta en digital vårdgivare, t.ex. Medicheck.
      </p>
      <div style={{ marginTop: 25 }}>
        <MedicheckButton
          onClick={() => {
            window.open(medicheckLink)
            AnalyticsService.track({
              category: 'Navigation',
              action: 'Medicheck'
            })
          }}
        />
      </div>
    </div>
  </div>
)

const Recommendations = ({ visible, result }) => {
  if (!visible) return null

  const components =
    result <= 4 ? [InsightsLowLevel] : [Specialist, InsightsHighLevel]

  return (
    <div>
      <h4 style={{ marginBottom: 25 }}>Rekommenderat för dig</h4>
      {components.map((Component, i) => (
        <div className='item-margin' key={i}>
          <Component />
        </div>
      ))}
      <div style={{ paddingTop: 50, paddingBottom: 50 }}>
        <h4
          style={{
            textAlign: 'center',
            paddingLeft: '15%',
            paddingRight: '15%',
            marginBottom: 30
          }}
        >
          Finns det någon in din närhet som också röker? Dela testet!
        </h4>
        <InlineShareButtons
          config={{
            url: alexWebsiteLink,
            alignment: 'center',
            color: 'social',
            enabled: true,
            font_size: 16,
            language: 'en',
            networks: ['sms', 'whatsapp', 'messenger', 'facebook', 'twitter'],
            padding: 12,
            radius: 4,
            size: 40
          }}
        />
      </div>
    </div>
  )
}

export default Recommendations
