const displayTypes = {
  HINT: 'HINT',
  BUTTON: 'BUTTON',
  HEADER: 'HEADER',
  SELECT: 'SELECT',
  PARAGRAPH: 'PARAGRAPH',
  SUBHEADER: 'SUBHEADER',
  ARROW_BUTTON: 'ARROW_BUTTON'
}

export default displayTypes
