import { useEffect, useState } from 'react'

/**
 * Delays the change of a property by {@link delay} ms.
 * @param property any
 * @param delay number
 * @returns [currentProperty, isTransitioning] any, boolean
 */
const useDelay = (property, delay) => {
  const [isTransitioning, setIsTransitioning] = useState(false)
  const [currentProperty, setCurrentProperty] = useState(property)

  useEffect(() => {
    if (currentProperty !== property) {
      setIsTransitioning(true)

      setTimeout(() => {
        setIsTransitioning(false)
        setCurrentProperty(property)
      }, 250)
    }
  }, [property, currentProperty, delay])

  return [currentProperty, isTransitioning]
}

export default useDelay
