import React from 'react'
import { Row as BootstrapRow, Col } from 'react-bootstrap'

const columnSizes = {
  lg: 6,
  xl: 6,
  md: 6,
  sm: 12,
  xs: 12
}

const Row = ({ children, ...rest }) => (
  <BootstrapRow className='alex-row' {...rest}>
    <Col />
    <Col className='alex-column' {...columnSizes}>
      {children}
    </Col>
    <Col />
  </BootstrapRow>
)

export default Row
