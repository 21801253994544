import React from 'react'
import alexIcon from '../assets/images/alex-icon.png'

const Alex = () => (
  <div style={{ paddingTop: 30, paddingBottom: 10 }}>
    <img alt='Alex' src={alexIcon} className='alex-icon' />
  </div>
)

export default Alex
