import React from 'react'

const Transition = ({ isTransitioning, children }) => (
  <div
    className={`alex-transition-in ${
      isTransitioning ? 'alex-leave' : 'alex-enter'
    }`}
  >
    {children}
  </div>
)

export default Transition
