import React from 'react'
import Button from './Button'

const CookieBanner = ({ visible, onDismiss }) =>
  visible ? (
    <div className='alex-cookie-banner'>
      <p style={{ paddingRight: 40 }}>
        Denna webbplats använder cookies för att fungera bättre.
        <br /> Läs mer i vår{' '}
        <a
          href='https://www.alextherapeutics.com/privacy-policy-cookie-policy-en'
          className='alex-text-link'
        >
          Privacy Policy
        </a>
        .
      </p>
      <Button shrink text='OK' onClick={onDismiss} />
    </div>
  ) : null

export default CookieBanner
