import React from 'react'
import questions from '../data/questions'
import displayTypes from '../constants/displayTypes'

import Hint from './Hint'
import Header from './Header'
import Button from './Button'
import Select from './Select'
import Subheader from './Subheader'
import Paragraph from './Paragraph'
import ArrowButton from './ArrowButton'

const Questions = ({ currentIndex, onClick }) => {
  return questions.map((q, i) => {
    if (i === currentIndex) {
      return q.components.map((component, index) => {
        let Component

        switch (component.displayType) {
          case displayTypes.SUBHEADER:
            Component = Subheader
            break
          case displayTypes.HEADER:
            Component = Header
            break
          case displayTypes.PARAGRAPH:
            Component = Paragraph
            break
          case displayTypes.BUTTON:
            Component = Button
            break
          case displayTypes.SELECT:
            Component = Select
            break
          case displayTypes.HINT:
            Component = Hint
            break
          case displayTypes.ARROW_BUTTON:
            Component = ArrowButton
            break
          default:
            return null
        }

        if (Component) {
          return (
            <div key={index} className='alex-section'>
              <Component
                key={index}
                onClick={option => onClick(option, i)}
                {...component}
              />
            </div>
          )
        } else {
          return null
        }
      })
    } else {
      return null
    }
  })
}

export default Questions
