import React from 'react'

import whiteArrow from '../assets/images/whiteArrowRight.png'
import medicheckLogo from '../assets/images/medicheck_logo.png'

const buttonStyle = {
  padding: 0,
  width: '100%',
  border: 'none',
  backgroundColor: 'transparent',
  borderTop: '1px solid rgba(255,255,255,0.2)'
}

const wrapperStyle = {
  display: 'flex',
  paddingTop: 20,
  alignItems: 'center',
  flexDirection: 'row'
}

const containerStyle = {
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginLeft: 20,
  maxWidth: 200
}

const logoStyle = {
  widht: 75,
  height: 75
}

const titleStyle = {
  color: 'white',
  textAlign: 'left',
  marginBottom: 20
}

const textStyle = {
  color: 'white'
}

const arrowStyle = {
  height: 15,
  width: 15,
  marginBottom: 5,
  marginLeft: 10
}

const MedicheckButton = ({ onClick }) => (
  <button onClick={onClick} style={buttonStyle}>
    <div style={wrapperStyle}>
      <div>
        <img src={medicheckLogo} style={logoStyle} alt='medicheck' />
      </div>
      <div style={containerStyle}>
        <h5 style={titleStyle}>
          Prata med en läkare hos Medicheck
        </h5>
        <p className='small-text' style={textStyle}>
          Till Appen
          <img src={whiteArrow} alt='arrow' style={arrowStyle} />
        </p>
      </div>
    </div>
  </button>
)

export default MedicheckButton
