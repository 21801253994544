import React, { useState } from 'react'
import { Button as BootstrapButton } from 'react-bootstrap'

const Button = ({
  row,
  text,
  color,
  shrink,
  onClick,
  iconImg,
  arrowImg,
  className
}) => {
  const [isSelected, setIsSelected] = useState(false)

  const handleClick = () => {
    setIsSelected(!isSelected)
    onClick()
  }

  let rowClass = ''
  let widthClass = ''
  let colorClass = ''
  let selectedClass = ''

  switch (color) {
    case 'blue':
      colorClass += 'alex-blue-button'
      break
    case 'transparent':
      colorClass += 'alex-ghost-button'
      selectedClass += 'alex-ghost-button-selected'
      break
    case 'white':
      colorClass += 'alex-white-button'
      break
    case 'black':
      colorClass += 'alex-black-button'
      break
    case 'red':
      colorClass += 'alex-red-button'
      break
    case 'green':
      colorClass += 'alex-green-button'
      break
    default:
      colorClass += 'alex-blue-button'
      break
  }

  if (row) rowClass = 'alex-row-button'
  if (shrink) widthClass = 'alex-shrink-button'

  return (
    <BootstrapButton
      className={`alex-button ${colorClass} ${rowClass} ${widthClass} ${className} ${isSelected && selectedClass}`}
      onClick={handleClick}
    >
      <div>
        {iconImg && (
          <img
            src={iconImg}
            alt='button-icon'
            style={{ height: 20, width: 20, marginRight: 10 }}
          />
        )}
        {text}
      </div>
      {arrowImg && (
        <img
          src={arrowImg}
          alt='button-arrrow'
          style={{ height: 15, width: 15 }}
        />
      )}
    </BootstrapButton>
  )
}

export default Button
