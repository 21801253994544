import React from 'react'
import Button from './Button'

const Select = ({ options, onClick }) => {
  return options.map((option, index) => {
    return (
      <Button
        key={index}
        text={option.text}
        color='transparent'
        className='item-margin'
        onClick={() => onClick(option)}
      />
    )
  })
}

export default Select
