import { useState } from 'react'
import { Container } from 'react-bootstrap'
import './App.css'
import './Button.css'

import { useDelay } from './hooks'
import { AnalyticsService } from './services'

import {
  Row,
  Alex,
  Counter,
  Questions,
  BackButton,
  Transition,
  Recommendations,
  CookieBanner,
  ArrowButton
} from './components'

const App = () => {
  const [result, setResult] = useState(0)
  const [values, setValues] = useState({})
  const [currentIndex, setCurrentIndex] = useState(0)
  const [index, isTransitioning] = useDelay(currentIndex)
  const [cookieBannerVisible, setCookieBannerVisible] = useState(true)

  const handleTestResult = () => {
    let total = 0

    Object.values(values).forEach(value => {
      if (value) total += value
    })

    setResult(total)

    AnalyticsService.track({
      category: 'Result',
      action: 'Finished test',
      label: total,
      value: total
    })

    let nextIndex

    if (total <= 4) {
      nextIndex = 11
    } else if (total <= 7) {
      nextIndex = 12
    } else {
      nextIndex = 13
    }

    setTimeout(() => {
      setCurrentIndex(nextIndex)
    }, 3000)
  }

  const increment = () => {
    setCurrentIndex(currentIndex + 1)

    if (currentIndex === 9) {
      handleTestResult()
    }
  }

  const decrement = () => {
    if (currentIndex >= 0) {
      setCurrentIndex(currentIndex - 1)
    }
  }

  const handleQuestion = (option, index) => {
    if (index === 0) {
      AnalyticsService.track({
        category: 'Navigation',
        action: 'Started test'
      })

      return increment()
    }


    if (option.analytics) {
      AnalyticsService.track(option.analytics)
    }

    setValues({ ...values, [index]: option.value })

    if (!option.isMultipleSelect) {
      increment()
    }
  }

  return (
    <div className='h-100'>
      <CookieBanner
        visible={cookieBannerVisible}
        onDismiss={() => setCookieBannerVisible(false)}
      />
      <Container fluid className='vertical-center'>
        <div className='alex-main-container'>
          <Transition isTransitioning={isTransitioning}>
            <Row>
              <Alex />
              <Counter count={index} lowerLimit={0} upperLimit={9} />
            </Row>
            <Row>
              <Questions currentIndex={index} onClick={handleQuestion} />
              <Recommendations visible={index > 10} result={result} />
            </Row>
          </Transition>
          <Row>
            {currentIndex === 0 && (
              <div className='alex-compliance-container'>
                <p className='alex-footer-text'>
                  Rättigheterna till appen Alex ägs av Alex Therapeutics AB.
                  Appen marknadsförs i samarbete med Pfizer Innovations AB.
                </p>
                <p className='alex-footer-text alex-compliance-text'>
                  PP-CHM-SWE-0440 Feb 2021
                </p>
              </div>
            )}
          </Row>
        </div>
        <Row style={{ padding: 0, paddingBottom: 20 }}>
          <div
            style={{
              display: 'flex', 
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <BackButton
              onClick={decrement}
              visible={currentIndex > 0 && currentIndex < 10}
            />
            {currentIndex === 7 && <ArrowButton onClick={increment} text='Gå vidare' shrink />}
          </div>
        </Row>
      </Container>
    </div>
  )
}

export default App
