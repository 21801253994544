import React from 'react'

const Counter = ({ count, lowerLimit, upperLimit }) => {
  if (count <= lowerLimit || count > upperLimit) {
    return null
  } else {
    return <p className='subheader'>Fråga {count}/{upperLimit}</p>
  }
}

export default Counter
