import ReactGA from 'react-ga'

const trackingId = 'UA-121964161-2'

class AnalyticsService {
  init = () => {
    ReactGA.initialize(trackingId)
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  track = event => {
    fetch(
      'https://eu-west-1-prod.alextherapeutics.com/api/trackingEvent/anonymous',
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          eventName: 'FagerstromTestEvent',
          properties: {
            urlParams: Object.fromEntries(new URLSearchParams(window.location.search)),
            ...event
          }
        })
      }
    )

    ReactGA.event(event)
  }
}

export default new AnalyticsService()
