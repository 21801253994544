import React from 'react'
import { Button as BootstrapButton } from 'react-bootstrap'

import whiteArrow from '../assets/images/whiteArrowRight.png'

const Button = ({ text, shrink, onClick, className }) => {
  let widthClass = ''

  if (shrink) widthClass = 'alex-shrink-button'

  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: shrink ? undefined : 1
  }

  return (
    <BootstrapButton
      className={`alex-button alex-row-button alex-blue-button ${widthClass} ${className}`}
      onClick={onClick}
    >
      <div style={divStyle} />
      <div style={divStyle}>
        {text}
      </div>
      <div style={{ ...divStyle, justifyContent: 'flex-end', marginLeft: shrink ? 15 : 0 }}>
        <img
          src={whiteArrow}
          alt='button-arrow'
          style={{ height: 20, width: 20 }}
        />
      </div>
    </BootstrapButton>
  )
}

export default Button
