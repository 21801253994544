import React from 'react'

const Paragraph = ({ text }) => {
  const strings = text.split('\n')

  return strings.map((s, i) => {
    return (
      <p className='item-margin' key={i}>
        {s}
      </p>
    )
  })
}

export default Paragraph
